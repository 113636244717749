<template>
<div class="login">
  <div class="bgimage">
    <img src="https://charge-public-bucket-prod.oss-cn-shenzhen.aliyuncs.com/login.png" alt="">
  </div>
  <div class="content">
    <h1>
      登录系统
    </h1>
    <el-input class="input" v-model="inputData.username" placeholder="请输入用户名" prefix-icon="el-icon-user"></el-input>
    <el-input class="input" v-model="inputData.password" placeholder="请输入密码" prefix-icon="el-icon-lock" show-password></el-input>
    <!--<el-input class="input" v-model="inputData.verifyCode" placeholder="请输入验证码" prefix-icon="el-icon-mobile-phone"></el-input>-->
    <!--<div class="verfiycode">-->
      <!--<span v-for="(item, index) in codeList" :key="index" :style="style[index]">{{item.code}}</span>-->
      <!--<span style="cursor: pointer" @click="refreshCode">看不清楚，换一张</span>-->
    <!--</div>-->
    <div class="btn" @click="buttonClick" :class="{disabled:!$util.isFullObj(inputData)}">
      登录
    </div>
    <!-- <el-button type="primary" round :disabled="!$util.isFullObj(inputData)" @click="buttonClick">登录</el-button> -->
  </div>
</div>
</template>

<script>
export default {
  data() {
    return {
      inputData: {
        username: "",
        password: "",
      },
       codeList:[],
        style:[]
    };
  },
  created() {
      //this.refreshCode();
      document.onkeydown = (e) => {
          let _key = window.event.keyCode;
          //!this.clickState是防止用户重复点击回车
          if (_key === 13) {
              this.buttonClick();
          }
      };

  },
  methods: {
      getStyle () {
          var data = {};
          let rgb = [Math.round(Math.random() * 220), Math.round(Math.random() * 240), Math.round(Math.random() * 200)];
          data.color=`rgb(${rgb})`;
          data.fontSize=`1${[Math.floor(Math.random() * 10)]}px`;
          data.padding=`${[Math.floor(Math.random() * 10)]}px`;
          data.transform=`rotate(${Math.floor(Math.random() * 90) - Math.floor(Math.random() * 90)}deg)`;
          return `color: ${data.color}; font-size: ${data.fontSize}; padding: ${data.padding}; transform: ${data.transform}`
      },
      refreshCode () {
          this.$request
              .getVeifyCode({})
              .then((res) => {
                  var arr = [];
                  for(var i=0;i< res.data.length;i++){
                      arr.push({ code:res.data.charAt(i)});
                      this.style[i]=this.getStyle();
                  }
                  this.codeList=arr;
              });
      },
    buttonClick() {
      this.$request
        .publicKey({})
        .then((res) => {
          if (res.code == 0) {
            var uuid = res.data;
            var data = {};
            data.loginName = this.inputData.username;
            data.pwd = this.$util.encryptedData(uuid, this.inputData.password);
            data.uuid = uuid;
            data.verifyCode =  this.inputData.verifyCode;
            this.$request.login(data).then((res) => {
              if (res.code == 0) {
                localStorage.setItem('curUserType', res.data.userType);
                localStorage.setItem('curUserName', res.data.userName);
                localStorage.setItem('curUserInfo123', JSON.stringify(res.data));
                this.$router.push("/");
              }
            })
          }
        });
    },
  },
};
</script>

<style lang="less">
.login {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  
  .bgimage {
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 50%;
    left: 65%;
    background-color: #fff;
    border-radius: 10px;
    padding: 10px 20px;
    transform: translateY(-50%);

    h1 {
      font-size: 30px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #3B78E6;
      width: 100%;
      text-align: center;
      border-bottom: 1px solid #e5e5e5;
      padding-bottom: 20px;
    }

    .input {
      width: 300px;
      margin: 10px 0;
    }

    .verfiycode {
      width: 100%;
      display: flex;
      line-height: 36px;
      padding: 10px 0;
      img {
        width: 104px;
        height: 36px;
      }

      span {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #3375ED;
      }
    }

    .btn {
      margin-top: 20px;
      margin-bottom: 20px;
      width: 100%;
      height: 50px;
      line-height: 50px;
      background: #3375ED;
      border-radius: 4px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #FFFFFF;
      text-align: center;
      cursor: pointer;

      &.disabled {
        cursor: no-drop;
      }
    }
  }
}
</style>
