<template>
  <div class="about">
  </div>
</template>
<script>
    export default {
        data() {
            return {};
        },
        created() {
            let token = this.$route.query.sidfjiweijwcnkmawp;
            var data = {};
            data.sidfjiweijwcnkmawp = token;
            this.$request.loginByToken(data).then((res) => {
                if (res.code == 0) {
                    localStorage.setItem('curUserType', res.data.userType);
                    localStorage.setItem('curUserName', res.data.userName);
                    localStorage.setItem('curUserInfo123', JSON.stringify(res.data));
                    this.$router.push("/");
                }
            })
        }
    };
</script>
